import './progress-spinner-overlay.css';

import h from 'hyperscript';

import { commonI18n as i18n } from 'cadenza/utils/i18n/i18n';

export class ProgressSpinnerOverlay extends HTMLElement {

  #cancelButton = h('button.button', i18n('cancel'));

  constructor (cancelHandler) {
    super();
    this.#cancelButton.onclick = cancelHandler;
  }

  connectedCallback () {
    if (this.hasChildNodes()) {
      return;
    }

    this.classList.add('d-progress-spinner-overlay');
    this.append(
      h('d-progress-spinner.spinner-over-content'),
      this.#cancelButton
    );
  }

  setCancelHandler (cancelHandler) {
    this.#cancelButton.onclick = cancelHandler;
  }

}

customElements.define('d-progress-spinner-overlay', ProgressSpinnerOverlay);
