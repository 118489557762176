import { getParametersStartingWith } from 'cadenza/utils/url-param-utils';

export const HIERARCHY_PARAM_PREFIX = 'hierarchy.';

export function getCurrentHierarchyParameters (): Map<string, string> {
  const result = new Map<string, string>();
  getParametersStartingWith(getParameters(), HIERARCHY_PARAM_PREFIX).forEach((paramValue, paramName) => {
    result.set(paramName, paramValue);
  });
  return result;
}

function getParameters (): URLSearchParams {
  return new URLSearchParams(location.search);
}
